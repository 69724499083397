
import { Options, Vue } from 'vue-class-component';
import { StyleSettings } from '@okendo/reviews-common';

import store from '@/store';

@Options({
    props: {
        moduleData: Object
    },
    store
})
export default class customText extends Vue {
    moduleData?: StyleSettings.RecorderPlus.Module.CustomText;

    get customText(): StyleSettings.RecorderPlus.Module.CustomText | undefined {
        if (this.moduleData) {
            return this.moduleData;
        }
        else {
            // Default to post review as backup
            const { postReviewModules } = store.state.settings.settings;
            const customText = postReviewModules?.find(module => module.type === 'custom-text');
            return customText as StyleSettings.RecorderPlus.Module.CustomText | undefined;
        }
    }
}
