import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.customButton)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{
            'c-module--no-divider': !_ctx.customButton.addDividerBelow
        }, "c-module--gap-small"])
      }, [
        _createElementVNode("a", {
          href: _ctx.customButton.linkUrl,
          target: _ctx.customButton.openInNewWindow ? '_blank' : undefined,
          class: "c-button c-button--custom",
          rel: "noopener"
        }, _toDisplayString(_ctx.customButton.text), 9, _hoisted_1)
      ], 2))
    : _createCommentVNode("", true)
}