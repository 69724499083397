
import { Options, Vue } from 'vue-class-component';
import { StyleSettings } from '@okendo/reviews-common';

import store from '@/store';

@Options({
    props: {
        moduleData: Object
    },
    store
})
export default class customImage extends Vue {
    moduleData?: StyleSettings.RecorderPlus.Module.CustomImage;

    get customImage(): StyleSettings.RecorderPlus.Module.CustomImage | undefined {
        if (this.moduleData) {
            return this.moduleData;
        }
        else {
            // Default to post review as backup
            const { postReviewModules } = store.state.settings.settings;
            const customImage = postReviewModules?.find(module => module.type === 'custom-image');
            return customImage as StyleSettings.RecorderPlus.Module.CustomImage | undefined;
        }
    }
}
