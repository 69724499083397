import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ccf8abe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "c-shopMoreProducts"
}
const _hoisted_2 = { class: "u-fontSize-vue--medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_tile = _resolveComponent("product-tile")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_view_state = _resolveComponent("view-state")!
  const _directive_analytics = _resolveDirective("analytics")!

  return (_ctx.isShopMoreProductsVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.hasProducts && _ctx.viewState === 'content')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('Shop More Top-Reviewed Products')), 1),
              _createVNode(_component_swiper, {
                "slides-per-view": 'auto',
                "space-between": 12,
                observer: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                    return (_openBlock(), _createBlock(_component_swiper_slide, {
                      key: product.productId,
                      class: "c-shopMoreProducts--slide"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_product_tile, { product: product }, null, 8, ["product"]), [
                          [_directive_analytics, {
                            eventName: 'click-shop-more-products-card',
                            label: 'productId',
                            value: product.productId
                        }]
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true),
        _createVNode(_component_view_state, {
          "view-state": _ctx.viewState,
          onRetry: _ctx.loadTopReviewedProducts
        }, null, 8, ["view-state", "onRetry"])
      ]))
    : _createCommentVNode("", true)
}