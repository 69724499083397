import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.customText)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{
            'c-module--no-divider': !_ctx.customText.addDividerBelow
        }, "c-module--gap-small c-customText"]),
        innerHTML: _ctx.customText.text
      }, null, 10, _hoisted_1))
    : _createCommentVNode("", true)
}