
import { Options, Vue } from 'vue-class-component';
import { StyleSettings } from '@okendo/reviews-common';

import store from '@/store';

@Options({
    props: {
        moduleData: Object
    },
    store
})
export default class CustomButton extends Vue {
    moduleData?: StyleSettings.RecorderPlus.Module.CustomButton;

    get customButton(): StyleSettings.RecorderPlus.Module.CustomButton | undefined {
        if (this.moduleData) {
            return this.moduleData;
        }
        else {
            // Default to post review as backup
            const { postReviewModules } = store.state.settings.settings;
            const customButton = postReviewModules?.find(module => module.type === 'custom-button');
            return customButton as StyleSettings.RecorderPlus.Module.CustomButton | undefined;
        }
    }
}
