import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["href", "target"]
const _hoisted_2 = ["src", "width", "height"]
const _hoisted_3 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.customImage)
    ? (_openBlock(), _createElementBlock("figure", {
        key: 0,
        class: _normalizeClass([{
            'c-module--no-divider': !_ctx.customImage.addDividerBelow
        }, "c-module--gap-small c-customImage"])
      }, [
        (_ctx.customImage.linkUrl)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.customImage.linkUrl,
              target: _ctx.customImage.openInNewWindow ? '_blank' : undefined,
              rel: "noopener"
            }, [
              _createElementVNode("img", {
                src: _ctx.customImage.image.url,
                width: _ctx.customImage.image.width,
                height: _ctx.customImage.image.height,
                class: "c-customImage-image"
              }, null, 8, _hoisted_2)
            ], 8, _hoisted_1))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.customImage.image.url,
              width: _ctx.customImage.image.width,
              height: _ctx.customImage.image.height,
              class: "c-customImage-image"
            }, null, 8, _hoisted_3))
      ], 2))
    : _createCommentVNode("", true)
}