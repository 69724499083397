
import { Options, Vue } from 'vue-class-component';

import Bubble from '@/shared-components/Bubble.vue';
import StarRating from '@/shared-components/StarRating.vue';
import Range from '@/shared-components/input-components/Range.vue';
import Terms from '@/shared-components/Terms.vue';
import ProductTile from '@/shared-components/ProductTile.vue';
import store from '@/store';
import { ProductListViewPublic } from '@okendo/reviews-common';
import { getTopReviewedProducts, postErrorAnalytics } from '@/utils/api';
import { StoreMethod } from '@/store/storeTypings';
import { swiper, swiperSlide } from '@/utils/swiperUtil';
import ViewState, { ViewStateStatus } from '@/shared-components/ViewState.vue';

type Swiper = typeof swiper;

@Options({
    components: {
        Bubble,
        StarRating,
        Range,
        Terms,
        ProductTile,
        ViewState,
        swiper,
        swiperSlide
    },
    store
})
export default class ShopMoreProducts extends Vue {
    swiper?: Swiper;
    viewState: ViewStateStatus = 'loading';

    mounted(): void {
        if (!store.state.shopMoreProducts.isLoaded) {
            this.loadTopReviewedProducts();
        }
        else {
            this.viewState = 'content';
        }
    }

    get products(): ProductListViewPublic[] {
        return store.state.shopMoreProducts.products || [];
    }

    get hasProducts(): boolean {
        return !!this.products.length;
    }

    get isShopMoreProductsVisible(): boolean {
        return !store.state.shopMoreProducts.isLoaded || this.hasProducts;
    }

    async loadTopReviewedProducts(): Promise<void> {
        const ignoredProductIds = this.getIgnoredProductIds();
        try {
            const { products } = await getTopReviewedProducts();
            const filteredProducts = products.filter(p => !ignoredProductIds.includes(p.productId) && !!p.productUrl);
            store.commit<StoreMethod>({
                type: 'shopMoreProducts/UPDATE_PRODUCTS',
                products: filteredProducts
            });
            this.viewState = 'content';
        }
        catch (error) {
            postErrorAnalytics(error);
            this.viewState = 'error';
        }
    }

    getIgnoredProductIds(): string[] {
        const { remainingProductsToReview, reviewedProductIds } = store.state.order;
        return [
            store.state.product.product.productId,
            ...remainingProductsToReview?.map(p => p.productId) ?? [],
            ...reviewedProductIds ?? []
        ];
    }
}
