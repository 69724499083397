
import { Options, Vue } from 'vue-class-component';
import { ProductListViewPublic } from '@okendo/reviews-common';

import ProductImage from './ProductImage.vue';
import StarRating from '@/shared-components/StarRating.vue';
import store from '@/store';

@Options({
    props: {
        product: {
            type: Object,
            default: {
                productId: '',
                productName: ''
            }
        }
    },
    components: {
        ProductImage,
        StarRating
    },
    store
})
export default class ProductTile extends Vue {
    product!: ProductListViewPublic;
    productRating = '0';
    isLoading = false;

    get averageRating(): string {
        return this.product.averageRating.toString();
    }

    get reviewCount(): string {
        const reviewsCount = this.product.approvedReviewsCount;
        return this.$t('{reviewsCount} Review', { reviewsCount }, reviewsCount);
    }
}
