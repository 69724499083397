import { defineAsyncComponent } from 'vue';
import type * as Swiper from 'swiper/vue';

let cachedSwiper: typeof Swiper | undefined;
export async function loadSwiper(): Promise<typeof Swiper> {
    if (cachedSwiper) {
        return cachedSwiper;
    }
    cachedSwiper = await import(/* webpackChunkName: "vendorSwiper" */'swiper/vue');
    return cachedSwiper;
}

export const swiper = defineAsyncComponent(() => loadSwiper().then(s => s.Swiper));
export const swiperSlide = defineAsyncComponent(() => loadSwiper().then(s => s.SwiperSlide));
