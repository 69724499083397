import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63cf3522"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label", "href"]
const _hoisted_2 = { class: "c-productTile-productName u-fontWeight--bold u-marginVerticalUI--xxxSmall u-fontSize-vue--regular" }
const _hoisted_3 = { class: "c-productTile-reviewCount" }
const _hoisted_4 = { class: "c-button c-productTile-shopNow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_image = _resolveComponent("product-image")!
  const _component_star_rating = _resolveComponent("star-rating")!
  const _directive_prevent_click_in_preview = _resolveDirective("prevent-click-in-preview")!

  return _withDirectives((_openBlock(), _createElementBlock("a", {
    "aria-label": _ctx.product.productName,
    href: _ctx.product.productUrl,
    class: "c-productTile c-form-row u-paddingUI--xSmall",
    target: "_blank"
  }, [
    _createVNode(_component_product_image, {
      imageUrl: _ctx.product.productImageUrl,
      imageClass: 'o-productImage--reviewMore',
      showPlaceholderIfEmpty: true
    }, null, 8, ["imageUrl"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.product.productName), 1),
    _createVNode(_component_star_rating, {
      modelValue: _ctx.averageRating,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.averageRating) = $event)),
      ratingReadonly: "true",
      size: "xSmall"
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.reviewCount), 1),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('Shop Now')), 1)
  ], 8, _hoisted_1)), [
    [_directive_prevent_click_in_preview]
  ])
}